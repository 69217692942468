.App {
  text-align: center;
}

nav {
  height: 80px;
  border-bottom: 3px solid var(--rose);
  display: flex;
  align-items: center;
  padding: 20px 40px;
}

.nav-logo {
  height: 72px;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-header {
  padding: 80px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: var(--black);
}

.app-header p {
  font-size: 64px;
  margin-bottom: 0;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 160px;
  width: 100%;
}

.social-links img {
  margin: 0 20px;
}

footer {
  height: 160px;
  background-color: var(--rose);
  color: white;
  display: flex;
  align-items: start;
  justify-content: flex-end;
  padding: 20px;
}

@media screen and (max-width: 1000px) {
  .app-logo {
    height: 60vmin;
  }
}

@media screen and (max-width: 720px) {
  .app-logo {
    height: 75vmin;
  }

  footer {
    justify-content: center;
    align-items: center;
  }
}