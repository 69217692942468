:root {
  /* color vars */
  --black: #202020;
  --rose: #E01E30;
}

body {
  margin: 0;
  font-family: 'DIN Condensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}
